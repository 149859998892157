export const AccountInformationTabList = [
  {
    id: "business-details",
    name: "Business details",
    url: `/settings/business-details`,
    icon: "solid/info",
  },
  {
    id: "default-pricing",
    name: "Default Pricing",
    url: `/settings/default-pricing`,
    icon: "solid/dollar-sign",
  },
  {
    id: "price-lists",
    name: "Price Lists",
    url: `/settings/price-lists`,
    icon: "solid/file-invoice-dollar",
  },
  // {
  //   id: "discounts",
  //   name: "Discounts",
  //   url: `/settings/discounts`,
  //   icon: "solid/tags",
  // },
  {
    id: "licenses",
    name: "Licenses",
    url: `/settings/licenses`,
    icon: "solid/chart-line",
  },
  {
    id: "account",
    name: "Account",
    url: `/settings/account`,
    icon: "solid/user",
  },
  {
    id: "users",
    name: "Users",
    url: `/settings/users`,
    icon: "solid/users",
  },
  {
    id: "quoting-portal",
    name: "Quoting Portal",
    url: `/settings/quoting-portal`,
    icon: "solid/chart-line",
  },

]
