<template>
  <div class="container">
  <!-- <AccountInformationTopRow/> -->
  <div class="row">
    <div class="col">
      <div class="tw-bg-secondary tw-shadow-md tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-p-3 tw-gap-10 tw-mt-4">
        <h2 class="tw-tracking-wider tw-font-bold tw-text-base tw-uppercase tw-text-white">
        Settings</h2>
      </div>
    </div>
  </div>
  <div class="tw-mt-4 tw-mb-10">
    <div :key="activeTabIndex">
      <div class="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row">
        <Popover class="sm:tw-hidden tw-relative">
          <PopoverButton
            class="
              btn btn_default
              tw-border
              tw-capitalize
              tw-flex
              tw-font-normal
              tw-m-0
              tw-px-1.5
              tw-py-1.5
              tw-space-x-2
              tw-w-full
              tw-justify-between
              tw-whitespace-nowrap
              tw-text-white
              tw-bg-primary
              tw-border-primary
              tw-text-sm
            "
          >
            <div class="tw-space-x-1.5">
              <span> {{ currentRouteTabName }}  </span>
            </div>
            <svg-icon name="solid/chevron-down" />
          </PopoverButton>

          <transition
            enter-active-class="tw-transition tw-duration-100 tw-ease-out"
            enter-from-class="tw-transform tw-scale-95 tw-opacity-0"
            enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
            leave-active-class="tw-transition tw-duration-75 tw-ease-in"
            leave-from-class="tw-transform tw-scale-100 tw-opacity-100"
            leave-to-class="tw-transform tw-scale-95 tw-opacity-0"
          >
            <PopoverPanel
              v-slot="{ close }"
              class="
                tw-absolute tw-bg-white tw-py-2 tw-shadow tw-w-full
              "
              style="z-index: 11;"
            >
              <div class="tw-grid tw-grid-cols-1 tw-gap-1 tw-text-sm">
                <template v-for="(tab, index) in tabs" :key="index">
                  <router-link
                    class="
                      tw-text-left tw-space-x-2
                      btn btn_default
                      tw-m-0 tw-border-none tw-text-sm
                      hover:tw-bg-gray-200
                      tw-px-2 tw-font-normal
                    "
                    active-class="tw-bg-primary tw-bg-opacity-50"
                    :to="tab.url"
                    @click="close"
                  >
                    <span>{{ tab.name }} </span>
                  </router-link>
                </template>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <div
          class="
            tw-hidden
            sm:tw-flex
            tw-flex-col tw-gap-4 tw-bg-gray-100 tw-p-2
          "
          style="min-width: 180px;"
        >
          <template v-for="tab in tabs" :key="tab.name">
            <!-- <template> -->
            <router-link
              class="
                tw-text-left tw-space-x-2
                btn btn_default tw-bg-transparent text-navy
                tw-m-0 tw-border-none tw-text-sm
                hover:tw-bg-gray-200
                tw-px-2 tw-font-semibold tw-py-1.5 font-sansation-bold
              "
              :to="tab.url"
              active-class="tw-bg-primary tw-bg-opacity-50"

            >
              <div class="tw-flex tw-space-x-1">
                <div class="tw-w-5 tw-text-center">
                  <svg-icon v-if="tab.icon" :name="tab.icon" />
                </div>
                <span> {{ tab.name }}</span>
              </div>
              <!-- </a> -->
            </router-link>
            <!-- </template> -->
          </template>
        </div>
        <div class="tw-flex-1 tw-card tw-card-body with-scroll">
          <router-view> </router-view>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import AccountInformationTopRow from '@/views/AccountInformation/Partials/AccountInformationTopRow.vue'
import {AccountInformationTabList} from '@/constants/AccountInformationConstant.js'
import { useStore } from "vuex";

export default {
  name: "AccountInformationIndex",
  components: {
    AccountInformationTopRow,
    Popover,
    PopoverButton,
    PopoverPanel
  },
  data() {
    return {
      tabs : [],
      activeTabIndex : 0,
    }
  },
  computed: {
    currentRouteTabName (){
      return this.tabs.find((x) => this.$route.path.includes(x.url))?.name;
    }
  },
  async mounted() {
    await this.$store.dispatch('registration/getState');
    const result =  (await this.$store.dispatch('auth/getSettingsMenu')).data
    this.tabs = AccountInformationTabList.filter(menu => result.includes(menu.id))
  }
}
</script>

<style>
.svg-icon-secondary-colour {
  color: red;
  fill: currentColor;
}

.svg-icon-primary-colour {
  color: green;
  fill: currentColor;
}

</style>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.font-sansation-bold {
  font-family: Sansation_Bold;
}
.title {
  font-family: $bold;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  font-size: 31px;
  text-transform: uppercase;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 27px;
  }
}
</style>
