<template>
  <div
    class="
      tw-bg-secondary tw-shadow-md tw-grid tw-grid-cols-1
      md:tw-grid-cols-2
      tw-p-3 tw-gap-10
    "
  >
    <button
      type="button"
      class="
        btn btn_default
        tw-m-0 tw-p-0 tw-w-max tw-text-white tw-border-none
      "

    >
      <!--      @click="$router.push(backUrl)"-->
      <svg-icon name="solid/chevron-left"></svg-icon>
      <!--      <span class="tw-ml-4" :class="{ 'tw-animate-pulse': isInfoLoading }">
              {{ isInfoLoading ? "..." : info.trading_name }}
            </span>-->
    </button>
  </div>
</template>

<script>
export default {
  name: "AccountInformationTopRow"
}
</script>

<style scoped>

</style>
